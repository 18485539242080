<template>
  <validation-observer ref="setttingsRules">
    <b-form @submit.prevent="submit" v-if="!formLoading">
      <b-card class="py-1">
        <!-- {{formData}} -->
        <!-- {{settings}} -->
        <b-row>
          <!-- name -->
          <b-col
            v-for="(field, fIndex) in formFields"
            :key="fIndex"
            :cols="field.cols == 'auto' ? '' : field.cols || 6"
            class="align-self-center"
          >
            <div
              v-if="
                !field.vifAttribute ||
                formData[field.vifAttribute] != field.vifValue
              "
            >
              <b-form-group :label="$t(field.name)" :label-for="field.name">
                <validation-provider
                  #default="{ errors }"
                  :rules="`${field.rules ? field.rules : ''}`"
                  :name="field.name"
                >
                  <b-input-group v-if="field.type == 'localizedText'">
                    <b-form-input
                      :id="field.name"
                      :placeholder="formData[field.model].locale"
                      v-model="
                        formData[field.model][formData[field.model].locale]
                      "
                      :state="errors.length > 0 ? false : null"
                      @change="checkEmptyLocale(field.model)"
                    />
                    <b-input-group-append>
                      <b-dropdown
                        :text="formData[field.model].locale"
                        variant="outline-primary"
                      >
                        <b-dropdown-item
                          v-for="lang in availableLocales"
                          :key="lang"
                          @click="changeInputLocale(field.model, lang)"
                        >
                          {{ $t(lang) }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <!-- <b-button variant="primary" @click="changeNameLang">
                    {{ nameLang }}
                  </b-button> -->
                    </b-input-group-append>
                  </b-input-group>
                  <v-select
                    v-else-if="field.type == 'searchSelect'"
                    :id="field.name"
                    :options="field.options"
                    :label="field.label"
                    :reduce="(item) => item.id"
                    v-model="formData[field.model]"
                    :disabled="field.disabled"
                    @search="field.searchItems"
                  ></v-select>
                  <v-select
                    v-else-if="field.type == 'searchSelectItem'"
                    :id="field.name"
                    :options="field.options"
                    :label="field.label"
                    :reduce="(item) => item"
                    v-model="formData[field.model]"
                    :disabled="field.disabled"
                    @search="field.searchItems"
                  ></v-select>

                  <b-form-select
                    v-else-if="field.type == 'select'"
                    :id="field.name"
                    :options="field.options"
                    :text-field="field.label"
                    value-field="value"
                    v-model="formData[field.model]"
                    :disabled="field.disabled"
                  ></b-form-select>

                  <b-form-checkbox
                    v-else-if="field.type == 'checkbox'"
                    v-model="formData[field.model]"
                    :id="field.name"
                    switch
                    inline
                  >
                    <!-- {{field.name}} -->
                  </b-form-checkbox>
                  <h4 v-else-if="field.type == 'groupTitle'" class="mb-1">
                    {{ $t(field.title) }}
                  </h4>
                  <div v-else-if="field.type == 'divider'" class="w-100">
                    <hr />
                  </div>
                  <div v-else-if="field.type == 'file'" class="d-flex">
                    <b-form-file
                      :type="field.type"
                      :id="field.name"
                      v-model="formData[field.model]"
                      :state="errors.length > 0 ? false : null"
                    />
                    <img :src="field.extUrl" alt="image" v-if="field.extUrl" />
                  </div>
                  <div
                    v-else-if=" field.type == 'map'"
                  >
                    <div
                      id="map-holder"
                      style="height: 700px;"
                    ></div>
                  </div>
                  <b-form-input
                    v-else
                    :type="field.type"
                    :id="field.name"
                    :placeholder="field.placeholder"
                    v-model="formData[field.model]"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <b-card-footer>
          <b-button
            variant="primary"
            class="btn"
            type="submit"
            v-if="resolvePermission('settings.editSettings')"
          >
            {{ $t("save") }}
          </b-button>
        </b-card-footer>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BCardFooter,
  BCardHeader,
  BCardTitle,
  BFormSelect,
  BFormFile,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardFooter,
    BCardHeader,
    BCardTitle,
    BFormSelect,
    vSelect,
    BFormFile,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      required,
      email,
      between,
      url,
      formData: {},
      editObj: {},
      formLoading: true,
      logoBase64: null,
      favBase64: null,
    };
  },

  mounted() {
    this.initializeFormData();
  },
  watch: {
    formData: {
      handler(e) {
        if (e.logo) {
          this.getBase64(e.logo).then((res) => {
            this.logoBase64 = res;
          });
        }
        if (e.favicon) {
          this.getBase64(e.favicon).then((res) => {
            this.favBase64 = res;
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["getSettings", "updateSettings"]),
    async initMap() {
      let map, mapHolder, settingsCenter;
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
      const kuweit = new google.maps.LatLng(29.3117, 47.4818);
      mapHolder = document.getElementById("map-holder");
      settingsCenter = new google.maps.LatLng(this.formData.lat, this.formData.long);

      map = new google.maps.Map(mapHolder, {
        center: settingsCenter || kuweit,
        zoom: 13,
        mapId: 'b0ea69c70927022d'
      });
      this.marker = new AdvancedMarkerElement({
        map,
        position: settingsCenter || kuweit,
      })
      map.addListener('click', this.mapClicked)
    },
    mapClicked(e) {
      this.formData.long = e.latLng.lng();
      this.formData.lat = e.latLng.lat();
      this.marker.position = { lat: this.formData.lat, lng: this.formData.long }
    },
    submit() {
      this.$refs.setttingsRules.validate().then((res) => {
        if (res == true) {
          const data = this.organizeFormData();
          setTimeout(() => {
            this.updateSettings(data).then((res) => {
              this.initializeFormData();
            });
          }, 300);
        }
      });
    },
    organizeFormData() {
      let data = { ...this.formData };
      Object.values(data).forEach((field) => {
        if (field && (field.en || field.ar)) {
          delete field.locale;
        }
      });

      return {
        logo: this.logoBase64,
        favicon: this.favBase64,
        location_company: {
          lon: data.long,
          lan: data.lat,
        },
        colorTheme: {
          primary: data.colorPrimary,
          socendary: data.colorSecondary,
          accent: data.colorAccent,
        },
        fonts: {
          primary: data.fontPrimary,
          socendary: data.fontSecondary,
        },
        social_media_links: [
          {
            name: "facebook",
            link: data.facebook,
            icon: "",
          },
          {
            name: "instagram",
            link: data.instagram,
            icon: "",
          },
          {
            name: "whatsapp",
            link: data.whatsapp,
            icon: "",
          },
          {
            name: "twitter",
            link: data.twitter,
            icon: "",
          },
        ],
        address: { ...data.address },
        AppName: { ...data.AppName },
        description: { ...data.description },
        site_meta_title: { ...data.site_meta_title },
        site_meta_description: { ...data.site_meta_description },
        copy_rights: { ...data.copy_rights },
        email: data.email,
        phone: data.phone,
        fax: data.fax,
        keyWords: data.keyWords,
        facebook_pixel: data.facebook_pixel,
        google_tag_manager: data.google_tag_manager,
        maps_api_key: data.maps_api_key,
      };
    },
    initializeFormData() {
      this.formFields.forEach((field) => {
        if (field.type == "localizedText") {
          this.formData[field.model] = {
            locale: this.locale,
          };
        }
      });
      this.getSettings().then((res) => {
        let data = res.data.results;
        this.formData = {
          AppName: {
            ...this.formData.AppName,
            ar: data["AppName"] ? data["AppName"].ar : "",
            en: data["AppName"] ? data["AppName"].en : "",
          },
          address: {
            ...this.formData.address,
            ar: data.address ? data.address.ar : "",
            en: data.address ? data.address.en : "",
          },
          description: {
            ...this.formData.description,
            ar: data.description ? data.description.ar : "",
            en: data.description ? data.description.en : "",
          },
          // email: data.email || '',
          // phone: data.phone || '',
          // fax: data.fax,
          long: data.location_company ? data.location_company.lon : "",
          lat: data.location_company ? data.location_company.lan : "",

          // site_meta_title: {
          //   ...this.formData.site_meta_title,
          //   ar: data.site_meta_title ? data.site_meta_title.ar : '',
          //   en: data.site_meta_title ? data.site_meta_title.en : '',
          // },
          // site_meta_description: {
          //   ...this.formData.site_meta_description,
          //   ar: data.site_meta_description ? data.site_meta_description.ar : '',
          //   en: data.site_meta_description ? data.site_meta_description.en : '',
          // },
          // keyWords: data.keyWords || '',
          // copy_rights: {
          //   ...this.formData.copy_rights,
          //   ar: data.copy_rights ? data.copy_rights.ar : '',
          //   en: data.copy_rights ? data.copy_rights.en : '',
          // },
          // facebook_pixel: data.facebook_pixel || '',
          // google_tag_manager: data.google_tag_manager || '',
          // maps_api_key: data.maps_api_key || '',

          // colorPrimary: data.colorTheme.primary ? data.colorTheme.primary : '',
          // colorSecondary: data.colorTheme.socendary ? data.colorTheme.socendary : '',
          // colorAccent: data.fonts.accent ? data.fonts.accent : '',
          // fontPrimary: data.fonts.primary ? data.fonts.primary : '',
          // fontSecondary: data.fonts.socendary ? data.fonts.socendary : '',

          facebook:
            data.social_media_links.length > 0 &&
            data.social_media_links.find((el) => el.name == "facebook")
              ? data.social_media_links.find((el) => el.name == "facebook").link
              : "",
          whatsapp:
            data.social_media_links.length > 0 &&
            data.social_media_links.find((el) => el.name == "whatsapp")
              ? data.social_media_links.find((el) => el.name == "whatsapp").link
              : "",
          instagram:
            data.social_media_links.length > 0 &&
            data.social_media_links.find((el) => el.name == "instagram")
              ? data.social_media_links.find((el) => el.name == "instagram")
                  .link
              : "",
          twitter:
            data.social_media_links.length > 0 &&
            data.social_media_links.find((el) => el.name == "twitter")
              ? data.social_media_links.find((el) => el.name == "twitter").link
              : "",
        };
        this.formLoading = false;
        
        setTimeout(() => {
          this.initMap();
        }, 100);
      });
    },
    changeInputLocale(model, lang) {
      this.formData[model].locale = lang;
      this.$forceUpdate();
    },
    checkEmptyLocale(model) {
      for (const [key, value] of Object.entries(this.formData[model])) {
        if (!value || value == null || value == "") {
          this.formData[model][key] =
            this.formData[model][this.formData[model].locale];
        }
      }
    },
  },

  computed: {
    ...mapGetters(["settings", "loading"]),
    availableLocales() {
      return this.$i18n.availableLocales;
    },
    formFields() {
      return [
        { type: "groupTitle", title: this.$t("General Settings"), cols: 12 },
        { name: this.$t("App Name"), type: "localizedText", model: "AppName" },
        { name: this.$t("logo"), type: "file", model: "logo", cols: 6 },
        {
          name: this.$t("description"),
          type: "localizedText",
          model: "description",
          cols: 12,
        },
        // {name: this.$t('favicon'), type: 'file', model: 'favicon', cols: 3},
        {
          name: this.$t("address"),
          type: "localizedText",
          model: "address",
          cols: 12,
        },
        // {name: this.$t('email'), rules: 'email', type: 'email', model: 'email'},
        // {name: this.$t('phone'), type: 'tel', model: 'phone'},
        // {name: this.$t('fax'), type: 'tel', model: 'fax'},
        // { name: this.$t("Longitude"), type: "text", model: "long" },
        // { name: this.$t("Latitude"), type: "text", model: "lat" },
        { name: this.$t("Address"), type: "map", model: "mapData", cols: 12 },

        // { type: 'divider', cols: 12},
        // { type: 'groupTitle', title: this.$t('Site Meta Settings'), cols: 12},
        // {name: this.$t('Site meta title'), type: 'localizedText', model: 'site_meta_title'},
        // {name: this.$t('Site meta description'), type: 'localizedText', model: 'site_meta_description'},
        // {name: this.$t('Keywords'), type: 'text', model: 'keyWords', cols: 12},
        // {name: this.$t('Copy rights'), type: 'localizedText', model: 'copy_rights'},
        // {name: this.$t('Facebook pixel'), type: 'text', model: 'facebook_pixel'},
        // {name: this.$t('Google tag manager'), type: 'text', model: 'google_tag_manager'},
        // {name: this.$t('Maps api key'), type: 'text', model: 'maps_api_key'},
        { type: "divider", cols: 12 },
        { type: "groupTitle", title: this.$t("Social Media"), cols: 12 },
        {
          name: this.$t("Facebook"),
          rules: "url",
          type: "text",
          model: "facebook",
        },
        {
          name: this.$t("Twitter"),
          rules: "url",
          type: "text",
          model: "twitter",
        },
        { name: this.$t("Whatsapp"), type: "number", model: "whatsapp" },
        // {name: this.$t('Youtube'), rules: 'url', type: 'text', model: 'youtube'},
        { name: this.$t("Instagram"), type: "text", model: "instagram" },

        // { type: 'divider', cols: 12},
        // { type: 'groupTitle', title: this.$t('Site Theme'), cols: 12},
        // {name: this.$t('Primary theme color'), type: 'color', model: 'colorPrimary', cols: 4},
        // {name: this.$t('Secondary theme color'), type: 'color', model: 'colorSecondary', cols: 4},
        // {name: this.$t('Accent theme color'), type: 'color', model: 'colorAccent', cols: 4},
        // {name: this.$t('Primary theme font'), type: 'text', model: 'fontPrimary'},
        // {name: this.$t('Secondary theme font'), type: 'text', model: 'fontSecondary'},
      ];
    },
    exampleFields() {
      return [
        {
          name: "searchSelect",
          type: "searchSelect",
          rules: "required",
          options: ["items"],
          label: "label",
          model: "selectAttr",
          disabled: false,
          searchItems: (searchText) => console.log("searching", searchText),
        },
        {
          name: "localizedText",
          type: "localizedText",
          rules: "",
          model: "selectAttr",
        },
        {
          name: "select",
          type: "select",
          rules: "required",
          options: ["items"],
          label: "label",
          model: "selectAttr",
        },
        {
          name: "checkbox",
          type: "checkbox",
          rules: "required",
          model: "selectAttr",
        },
        {
          name: "color",
          type: "color",
          placeholder: "placeholder",
          rules: "required",
          model: "selectAttr",
        },
        {
          name: "phone",
          type: "tel",
          placeholder: "placeholder",
          rules: "required",
          model: "selectAttr",
          vifAttribute: "field.vifAttribute",
          vifValue: "field.vifValue",
        },
        {
          name: "file",
          type: "file",
          rules: "required",
          model: "selectAttr",
        },
        {
          title: "Title Here",
          type: "groupTitle",
        },
        {
          type: "divider",
        },
      ];
    },
  },
};
</script>